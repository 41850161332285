

// Customize slick slider arrows
.slick-arrow {
  position: absolute;
  background-color: $primary;
  height: 3.75rem;
  width: 2.5rem;
  text-align: center;
  color: $white;
  line-height: 3.75rem;
  z-index: 1;
  top: 0;
  bottom: 0;
  margin: auto 0;
  opacity: 0.6;
  transition: $transition-base;
  cursor: pointer;
  &.slick-prev {
    right: 15px;
  }
  &.slick-next {
    left: 15px;
  }
  &:active {
    opacity: 1;
  }
}
@include media-breakpoint-up(lg) {
  .slick-arrow:hover {
    opacity: 1;
  }
}
