

.callout {
  padding: 6.25rem 0;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('/assets/img/bg-callout.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: scroll;
  .callout-text {
    font-size: 2rem;
    line-height: 1.25;
  }
  @include media-breakpoint-up(lg) {
    background-attachment: fixed;
    .callout-text {
      font-size: 2.5rem;
    }
  }
}

.callout-2 {
  padding: 6.25rem 0;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('/assets/img/bg-callout-2.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: scroll;
  .callout-text {
    font-size: 2rem;
    line-height: 1.25;
  }
  @include media-breakpoint-up(lg) {
    background-attachment: fixed;
    .callout-text {
      font-size: 2.5rem;
    }
  }
}

.callout-full {
  @media (min-width: 992px) {
    height: 100vh;
  }
}

.btn-callout {
  padding: 3rem 0;
  font-weight: $font-weight-base !important;
  font-family: $font-family-sans-serif !important;
  text-transform: none;
  letter-spacing: 0;
  font-size: 1.5rem;
  background-color: $black;
  color: $white;
  &:hover {
    color: $white;
    background-color: $primary;
  }
}
