
.footer {
  padding: 6.25rem 0 2rem;
  background: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.85)), url('/assets/img/bg-footer.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: scroll;
  a {
    text-decoration: none;
    &:hover {
      color: $white !important;
    }
  }
  .footer-social {
    padding: 6.25rem 0;
  }
  .footer-copyright {
    font-size: 0.9rem;
  }
  @include media-breakpoint-up(lg) {
    background-attachment: fixed;
  }
}
