// Colors
$primary: #808080 !default;

// Typography
$font-size-base: 1.125rem !default;
$font-weight-base: 300 !default;

$font-family-sans-serif: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;
$headings-font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;

$lead-font-size: 1.25rem !default;

$headings-font-weight: 900 !default;

// Transitions
$transition-base: all 0.15s ease-in-out !default;
$transition-link: all 0.1s ease-in-out !default;

// Borders
$border-radius: 0 !default;
$border-radius-lg: 0 !default;
$border-radius-sm: 0 !default;

// Buttons
$btn-font-weight: 900 !default;

$input-btn-padding-y: 0.75rem !default;
$input-btn-padding-x: 1.25rem !default;

$btn-padding-y: $input-btn-padding-y !default;
$btn-padding-x: $input-btn-padding-x !default;
